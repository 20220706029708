window.VegasListener = {
	onReady: () => {
		Vegas.carousel();
		Vegas.enablePlugins();
		Vegas.other();
		Vegas.fixedScrollTo($(window));
		Vegas.tooltip();
		Vegas.fixedHeader($(window));
		Vegas.popover();
	},

	onLoad: () => {
		Vegas.preloader();
	},

	onClick: () => {
		Vegas.popup();
	},

	onScroll: ($self) => {
		Vegas.fixedScrollTo($self);
		Vegas.fixedHeader($self);
	},

	onResize: ($self) => {
		Vegas.carousel();
	},
};

window.Vegas = {
	log: function () {
		console.group('vegas studio');
		console.log("%cvegas app was initialization","color: green;");
		console.log('https://vegas-dev.com/');
		console.groupEnd();
	},

	preloader: function () {

	},

	enablePlugins: function () {
		// navigation
		new VGNav({
			expand: 'lg',
			toggle: '<i class="fal fa-chevron-down"></i>'
		});

		// animation
		AOS.init({
			once: true,
		});

		// scroll
		$('[data-scrollTo]').vgScrollToSpy();
	},

	popup: function () {
		$('[data-modal]').click(function () {
			ModalVegas.init($(this));

			return false;
		});
	},

	popover: function() {
		let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
		popoverTriggerList.map(function (popoverTriggerEl) {
			return new bootstrap.Popover(popoverTriggerEl, {
				html: true,
				container: 'body',
			})
		});
	},

	tooltip: function () {
		let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
		tooltipTriggerList.map(function (tooltipTriggerEl) {
			return new bootstrap.Tooltip(tooltipTriggerEl)
		})
	},

	carousel: function () {
		VegasCarousel.init();
	},

	other: function () {
		// default page
		$('.vg-page-default, .page-default').each(function () {
			let $self = $(this);

			// Table
			let $table = $self.find('table');
			$table.addClass('table table-hover table-striped');
			$table.wrap('<div class="table-responsive"></div>');

			// image
			$self.find('br').after('<span class="mb-5 d-block"></span>');
			$self.find('img').addClass('img-fluid');

			// AOS
			if (typeof $self.attr('data-aos-start') !== typeof undefined && $self.attr('data-aos-start') !== false) {
				let $tags = ['p', 'li', 'h2', 'h3'],
					start = parseInt($self.data('aos-start')),
					step = 100;

				for (let i = 1; i <= $tags.length; i++) {
					let tag = $tags[i - 1],
						$tag = $self.find(tag);

					if ($tag.length) {
						$.each($tag, function () {
							start = start + step;
							$(this).attr('data-aos', 'fade-up');
							$(this).attr('data-aos-delay', start);
						});
					}
				}

				AOS.init();
			}
		});
	},

	fixedScrollTo: function ($self) {
		let $sticky = $('.vg-scroll-top'),
			scroll = $self.scrollTop();

		if (scroll > 400) {
			$sticky.addClass('is-active');
		} else {
			$sticky.removeClass('is-active');
		}
	},

	fixedHeader: function ($self) {
		let $sticky = $('.vg-header').find('.vg-header-wrapper'),
			scroll = $self.scrollTop(),
			scroll_offset = $sticky.data('scroll-offset') || $sticky.find('.vg-header-top').height(),
			$body = $('body');

		$sticky.data('scroll-offset', scroll_offset);

		if (scroll > 0) {
			$body.addClass('vg-header--is-scroll');
			$sticky.addClass('scroll');

			if (scroll >= scroll_offset) {
				$sticky.removeClass('scroll');
				$sticky.addClass('fixed');
				$body.addClass('vg-header--is-fixed');
			} else {
				$sticky.removeClass('fixed');
				$body.removeClass('vg-header--is-fixed');
				$sticky.addClass('scroll');
			}
		} else {
			$body.removeClass('vg-header--is-scroll');
			$sticky.removeClass('scroll');
		}
	}
};
