window.$ = window.jQuery = require('jquery');

require('popper.js/dist/popper.min');
window.bootstrap = require('bootstrap/dist/js/bootstrap.min');

window.VGNav = require('vgnav/dist/js/vgnav');
window.AOS = require('aos/dist/aos');
require('vgfiles/dist/js/vgfiles');
require('vgformsender/dist/js/vgformsender.min');
require('vgscrollto/assets/js/jquery.vegasScrollTo');
require('vgsidebar/dist/js/vgsidebar');

require('@fancyapps/ui/dist/fancybox.esm');
require('inputmask/dist/inputmask');

require('../../vendor/modules/bg');
require('../../vendor/modules/carousel');
require('../../vendor/modules/modal');
require('../../vendor/modules/mask');
require('./modules/vegas');
require('./modules/preloader');
require('./modules/lightgallery.min');
require('./modules/lightgallery');
require('./modules/lightvideo');
require('./modules/ym');
require('./functions');

// [[vegas]]
require('./custom');