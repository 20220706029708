window.VegasMask = {
	init: function () {
		let $selector = document.querySelectorAll('[data-mask]');

		if ($selector.length) {
			for (let i = 1; i <= $selector.length; i++) {
				let mask = $selector[i - 1].dataset.mask,
					format, opts = {};

				switch (mask) {
					case 'phone':
						format = $selector[i - 1].dataset.format || '8 (999) 999-99-99';
						opts = Object.assign({
							clearIncomplete: true,
							showMaskOnHover: true,
							onBeforeMask: function (pastedValue, opts) {
								return normalize_phone_inputmask(pastedValue)
							},
						}, opts);
						break;
				}

				if (format) {
					let im = new Inputmask(format, opts);
					im.mask($selector[i - 1]);
				}
			}
		}
	}
};

$(document).ready(function () {
	VegasMask.init();
});