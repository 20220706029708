(function ($) {
	"use strict";

	$(window).on('load', function() {
		VegasListener.onLoad();
	});

	$(window).scroll(function () {
		VegasListener.onScroll($(this));
	});

	$(document).ready(function () {
		VegasListener.onReady();
		VegasListener.onClick();
	});

	$(window).resize(function () {
		VegasListener.onResize($(this));
	});

	// [vegas]
	Vegas.log();
})(jQuery);