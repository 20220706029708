import Swiper, {Autoplay, EffectFade, Navigation, Pagination, Parallax, Thumbs} from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Pagination, Parallax, EffectFade, Autoplay, Thumbs]);
window.VegasCarousel = {
	init: function ($carousel, nativeOption = {}) {
		if ($carousel) {
			VegasCarousel.carousel($carousel, nativeOption);
		} else {
			let $carousels = $('.swiper-container');

			if ($carousels.length) {
				$carousels.each(function () {
					VegasCarousel.carousel($(this), {})
				});
			}
		}
	},

	carousel: function ($self, nativeOption) {
		let swiper,
			options,
			isInit = '' + $self.data('init');

		if (isInit !== 'false') {
			if (nativeOption.length) {
				options = nativeOption;
			} else {
				options = $.extend(
					VegasCarousel.setOptions($self),
					VegasCarousel.setNavigation($self),
					VegasCarousel.setPagination($self),
					VegasCarousel.setEffects($self),
					VegasCarousel.setAutoplay($self),
					VegasCarousel.setThumbs($self),
					VegasCarousel.setFirstVideoPlay($self)
				);
			}

			swiper = new Swiper('#' + $self.attr('id'), options);

			// API
			let event = $self.data('event') || 'none';
			VegasCarousel.events(swiper, event);
		}

		return swiper;
	},

	events: function (self, events) {
		if (events.length) {
			let $container = $(self.$el);
			let e = events.split(',');

			for (let i = 1; i <= e.length; i++) {
				// Смена слайдов при наведении на пагинацию
				if (e[i - 1] === 'dots-hover') {
					let $bullets = $container.find('.swiper-pagination-bullet'),
						$link = $container.closest('.vg-catalog-prd').find('.prd-link');

					$bullets.hover(function () {
						self.slideTo($(this).index());
					});

					$bullets.click(function () {
						location.href = $link.attr('href');
					})
				}

				// Зависимые слайдеры
				if (e[i - 1] === 'dependence') {
					let $dep_sliders = $('.' + $container.data('dependence'));

					self.on('slideChange', function (self) {
						$dep_sliders.each(function () {
							let $_self = $(this);

							let swiper = document.getElementById($_self.attr('id')),
								slider = swiper.swiper;

							slider.slideTo(self.activeIndex)
						});
					});
				}

				// Если есть видео запускаем
				if (e[i - 1] === 'video') {
					self.on('slideChange', function (self) {
						// Останавливаем все запущенные видео
						let $video = $container.find('[data-vg-video] video');
						if ($video.length) {
							$video.each(function () {
								$(this).trigger('stop');
							});
						}

						// Запускаем на текущем слайде
						if (self.slides[self.activeIndex].getElementsByTagName('video')[0]) {
							self.slides[self.activeIndex].getElementsByTagName('video')[0].currentTime = 0;
							self.slides[self.activeIndex].getElementsByTagName('video')[0].play();
						}
					});
				}

				// Если есть AOS анимация запускаем
				if (e[i - 1] === 'aos') {
					let $slide = $container.find('.swiper-slide');

					self.on('slideChangeTransitionStart', function (self) {
						$slide.find('.aos-init').removeClass('aos-init').removeClass('aos-animate');
					});
					self.on('slideChangeTransitionEnd', function (self) {
						AOS.init();
					});
				}
			}
		}
	},

	setOptions: function ($self) {
		return {
			breakpoints: $.extend(VegasCarousel.setBreakpoints($self), VegasCarousel.setResponsive($self)),
			slidesPerGroup: $self.data('per-group') || 1,
			height: $self.data('height') || 'auto',
			loop: !!$self.data('loop') || false,
			mousewheel: !!$self.data('mousewheel') || false,
			simulateTouch: '' + $self.data('touch-mouse') !== 'false',
			centeredSlides: !!$self.data('centered') || false,
			freeMode: !!$self.data('free-mode') || false,
			parallax: !!$self.data('parallax') || false,
			speed: $self.data('speed') || 1000,
			allowTouchMove: '' + $self.data('touch-move') !== 'false'
		}
	},

	setThumbs: function ($self) {
		let Thumbs = !!$self.data('thumbs') || false,
			thumbs;

		if (Thumbs) {
			let selector = $self.data('selector') || '#',
				$thumbs = $(selector + $self.data('thumbs'));

			if ($thumbs.length) {
				$thumbs.each(function () {
					let $_self = $(this);

					let options = $.extend(
						VegasCarousel.setOptions($_self),
						VegasCarousel.setNavigation($_self),
						VegasCarousel.setPagination($_self),
						VegasCarousel.setEffects($_self),
						VegasCarousel.setAutoplay($_self),
					);

					thumbs = new Swiper('#' + $_self.attr('id'), options);
				});

				return {
					thumbs: {
						swiper: thumbs
					}
				};
			}
		}
	},

	setAutoplay: function ($self) {
		let autoPlay = !!$self.data('autoplay') || false,
			autoPlayDelay = $self.data('autoplay-delay') || 5000,
			autoplay = {};

		if (autoPlay) {
			autoplay = {
				autoplay: {
					delay: autoPlayDelay,
					disableOnInteraction: false,
				}
			}
		}

		return autoplay;
	},

	setEffects: function ($self) {
		let effect = !!$self.data('effect') || false,
			effects = {};

		if (effect) {
			effects = {
				effect: 'fade',
				fadeEffect: {
					crossFade: true
				},
			}
		}

		return effects;
	},

	setNavigation: function ($self) {
		let nav = !!$self.data('nav') || false,
			navigation = {};

		if (nav && VegasCarousel.checkNavigable($self)) {
			navigation = {
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				}
			}
		}

		return navigation;
	},

	setPagination: function ($self) {
		let dots = !!$self.data('dots') || false,
			progressbarOpposite = !!$self.data('dots-progress') || false,
			pagination = {};

		if (dots && VegasCarousel.checkNavigable($self)) {
			pagination = {
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true,
					progressbarOpposite: progressbarOpposite,
					renderBullet: function (index, className) {
						return `<span class= ${className}><span>${index + 1}.</span></span>`;
					},
				},
			}
		}

		return pagination;
	},

	setBreakpoints: function ($self) {
		let items = $self.data('items') || false,
			spaceBetween = $self.data('margin') || 0;

		return {
			0: {
				slidesPerView: 1,
				slidesPerGroup: 1,
				spaceBetween: 0,
				autoHeight: true
			},
			640: {
				slidesPerView: items || 2,
				spaceBetween: spaceBetween
			},
			767: {
				slidesPerView: items || 2,
				spaceBetween: spaceBetween
			},
			991: {
				slidesPerView: items || 2,
				spaceBetween: spaceBetween
			},
			1199: {
				slidesPerView: items,
				spaceBetween: spaceBetween
			}
		};
	},

	setResponsive: function ($self) {
		let params = {};

		let xs = $self.data('xs') || false; // 0px
		if (xs) {
			params['0'] = xs;
		}

		let sm = $self.data('sm') || false; // 670
		if (sm) {
			params['640'] = sm;
		}

		let md = $self.data('md') || false; // 767px
		if (md) {
			params['767'] = md;
		}

		let lg = $self.data('lg') || false; // 991px
		if (lg) {
			params['991'] = lg;
		}

		return params;
	},

	checkNavigable: function ($self) {
		// Узнаем нужна ли на пагинация и навигация
		let $container = $('#' + $self.attr('id')),
			count_slides = $container.children('.swiper-wrapper').children('.swiper-slide').length,
			items = VegasCarousel.setOptions($container).breakpoints;

		if (window.innerWidth < 640 && count_slides <= items[0].slidesPerView) {
			$container.find('.swiper-pagination').hide();
			$container.find('.swiper-button-next').hide();
			$container.find('.swiper-button-prev').hide();

			return false;
		} else if ((window.innerWidth >= 640 && window.innerWidth < 767) && (count_slides <= items['640'].slidesPerView)) {
			$container.find('.swiper-pagination').hide();
			$container.find('.swiper-button-next').hide();
			$container.find('.swiper-button-prev').hide();

			return false;
		} else if ((window.innerWidth >= 767 && window.innerWidth < 991) && (count_slides <= items['991'].slidesPerView)) {
			$container.find('.swiper-pagination').hide();
			$container.find('.swiper-button-next').hide();
			$container.find('.swiper-button-prev').hide();

			return false;
		} else if (window.innerWidth > 1200 && (count_slides <= $self.data('items'))) {
			$container.find('.swiper-pagination').hide();
			$container.find('.swiper-button-next').hide();
			$container.find('.swiper-button-prev').hide();

			return false;
		}

		return true;
	},

	setFirstVideoPlay: function ($self) {
		if ($self.is(':first-child')) {
			$self.find('video').each(function () {
				$(this).trigger('play');
			});
		}

	}
};
