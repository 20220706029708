window.ModalVegas = {
	init: ($modal) => {
		let params = $modal.data();

		let option = {
			type:  $modal.data('modal') || 'bootstrap',
			target: $modal.attr('href') || $modal.data('target') || false,
			cloned_container: $modal.closest($modal.data('cloned-content')) || false,
		};

		option = $.extend(option, params);
		ModalVegas.scatter(option);
	},
	scatter: (option) => {
		if (option.target) {
			$.each(option, function (key, value) {
				let $element = $(option.target).find('[data-'+ key +']');

				if ($element.length) {
					if (key !== 'cloned') {
						let tagName = $element.prop('tagName').toLowerCase();

						if (tagName === 'input' || tagName === 'textarea' || tagName === 'select' || tagName === 'checkbox' || tagName === 'radio') {
							$element.val(value);
						} else {
							$element.html(value)
						}
					}
				}

				if (key === 'cloned' && option.cloned_container) {
					let classes = value.split(' '),
						$container = $(option.cloned_container);

					$.each(classes, function (k, v) {
						let content = $container.find('.' + v).html();

						$(option.target).find('.' + v).empty().html(content);
					});
				}

				if(key === 'opacity') {
					$('body').addClass('modal-opacity-' + value);
				}

				if(key === 'map') {
					ModalVegas.map('yaMap', value.split(','));
				}
			});

			if (option.type === 'bootstrap') {
				ModalVegas.modalBootstrap(option);
			} else if(option.type === 'fancybox') {
				ModalVegas.modalFancybox(option);
			}

			let $modal_close = $(option.target).find('.modal-close-block');
			if ($modal_close.length) {
				let cross = '<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 224.512 224.512" style="enable-background:new 0 0 224.512 224.512;" xml:space="preserve"><g><polygon style="fill:#010002;" points="224.507,6.997 217.521,0 112.256,105.258 6.998,0 0.005,6.997 105.263,112.254 0.005,217.512 6.998,224.512 112.256,119.24 217.521,224.512 224.507,217.512 119.249,112.254 "/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>';

				$modal_close.html('<button type="button" class="modal-close white" data-bs-dismiss="modal" aria-label="Close">' + cross + '</button>');
			}
		}
	},
	modalBootstrap: (option) => {
		$(option.target).modal('show');

		if ('reload' in option) {
			$(option.target).on('hidden.bs.modal', function (event) {
				location.reload();
			})
		}
	},
	modalFancybox: (option) => {
		let defaults = [{
			src: option.target
		}];

		if (option.fancy) {
			defaults[0] = $.extend(option.fancy, defaults[0]);
		}

		$.fancybox.open(defaults);
	},

	map: function (id, coordinate) {
		$('#' + id).find('ymaps').remove();

		let myMap;
		yaMapInit(id);

		function yaMapInit(map_ID) {
			ymaps.ready(init);

			function init() {
				let lat = coordinate[0];
				let lon = coordinate[1];

				myMap = new ymaps.Map(map_ID, {
					center: [lat, lon],
					zoom: 16
				}, {
					searchControlProvider: 'yandex#search',
					suppressMapOpenBlock: true,
				});

				myMap.controls.remove('typeSelector');
				myMap.controls.remove('fullscreenControl');
				myMap.controls.remove('trafficControl');
				myMap.controls.remove('searchControl');

				myMap.geoObjects
				.add(new ymaps.Placemark([lat, lon], {
					balloonContent: ''
				},  {
					preset: 'islands#icon',
					iconColor: '#8d197c'
				}));

				if(window.innerWidth < 1000){
					myMap.behaviors.get('drag').disable();
				}

				myMap.behaviors.disable('scrollZoom');
			}

			return false;
		}
	}
};