window.yaCounterReachGoal = function (goal) {
	if (typeof window['yaCounter'+App.ymId] !== 'undefined') {
		console.log('goal: ' + goal);
		window['yaCounter'+App.ymId].reachGoal(goal);
	}
};
window.yaConterHit = function(hit) {
	hit = hit || location.href;

	if (typeof window['yaCounter'+App.ymId] !== 'undefined') {
		console.log('hit: ' + hit);
		window['yaCounter'+App.ymId].hit(url);
	}
};

$(document).ready(function () {
	$(document).on('click', '[data-yagoal]', function () {
		yaCounterReachGoal($(this).data('yagoal'));
	});
	$(document).on('submit-success', 'form[data-form-yagoal]', function () {
		yaCounterReachGoal($(this).data('form-yagoal'));
	});
});