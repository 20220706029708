window.VegasBG = {
	init: ($container) => {
		if (!$container) {
			$container = $('body');
		}

		let $bg = $container.find('[data-vg-bg]'),
			$color = $container.find('[data-vg-color]'),
			$video = $container.find('[data-vg-video]');

		if ($bg.length) {
			VegasBG.bg($bg);
		}

		if ($video.length) {
			VegasBG.video($video);
		}

		if ($color.length) {
			VegasBG.color($color);
		}
	},

	bg: function ($bg) {
		$bg.each(function () {
			let $self = $(this),
				bg = $self.data('vg-bg'),
				repeat = $self.data('vg-repeat') || 'no-repeat',
				position = $self.data('vg-position') || 'center',
				size = $self.data('vg-size') || 'cover',
				attachment = $self.data('vg-attachment') || 'inherit',
				overlay = $self.data('vg-overlay') || false;

			$self.css({
				'background-image': 'url(' + bg + ')',
				'background-position': position,
				'background-repeat': repeat,
				'background-size': size,
				'background-attachment': attachment,
				'position': 'relative',
				'z-index': 0
			});

			if (overlay && overlay !== '') {
				$self.prepend('<div class="vg-bg-overlay"></div>');
				let $overlay = $self.find('.vg-bg-overlay'),
					overlayColor = $self.data('vg-overlaycolor') || '#000000';

				$overlay.css({
					'position': 'absolute',
					'z-index': 1,
					'width': '100%',
					'height': '100%',
					'top': 0,
					'left': 0,
					'background': VegasBG.hexToRgbA(overlayColor, (overlay / 100))
				});
			}
		});
	},

	color: function ($color) {
		$color.each(function () {
			let $self = $(this),
				color = $self.data('color'),
				overlay = $self.data('color-opacity') || 100;

			$self.css({
				'background-color': VegasBG.hexToRgbA(color, (overlay / 100))
			});
		});
	},

	video: function ($video) {
		$video.each(function () {
			let poster = $(this).data('vg-poster');
			let video = $(this).data('vg-video');

			$(this).prepend('<div class="vg-video-wrapper">' +
				'<video autoplay loop muted playsinline poster="' + poster + '">' +
				'  <source type="video/mp4" src="' + video + '" />' +
				'</video>' +
				'</div>')
		});
	},

	hexToRgbA: (hex, opacity = 1) => {
		let c;

		if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			c = hex.substring(1).split('');
			if (c.length === 3) {
				c = [c[0], c[0], c[1], c[1], c[2], c[2]];
			}
			c = '0x' + c.join('');
			return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
		}
	}
};

$(document).ready(function () {
	VegasBG.init();
});
