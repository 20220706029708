window.number_format = function (number, decimals = 0, dec_point = '.', thousands_sep = ',') {
	let sign = number < 0 ? '-' : '';

	let s_number = Math.abs(parseInt(number = (+number || 0).toFixed(decimals))) + "";
	let len = s_number.length;
	let tchunk = len > 3 ? len % 3 : 0;

	let ch_first = (tchunk ? s_number.substr(0, tchunk) + thousands_sep : '');
	let ch_rest = s_number.substr(tchunk)
		.replace(/(\d\d\d)(?=\d)/g, '$1' + thousands_sep);
	let ch_last = decimals ?
		dec_point + (Math.abs(number) - s_number)
			.toFixed(decimals)
			.slice(2) : '';

	return sign + ch_first + ch_rest + ch_last;
};
window.output_money = function (money) {
	return number_format(money, 0, ' ', ' ') + ' р';
};
window.normalize_phone_inputmask = function (val) {
	return val.replace(new RegExp(/\D/, 'g'), '').split('').reverse().slice(0, 10).reverse().join('');
};